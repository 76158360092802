<template>
  <div>
    <div class="lds-ring" v-if="show && type == 'loading'">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="mark" v-if="show && type == 'success'">
      <svg width="36" height="27" class="animated-check" :key="Math.random()">
        <path d="M3,12 15,24 33,3" />
      </svg>
    </div>
    <div class="mark" v-if="show && type == 'error'">
      <svg width="27" height="27" class="animated-cross">
        <path d="M3,24 24,3" />
        <path d="M3,3 24,24" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "loading",
    },
  },
};
</script>

<style>
.loader--inline {
  display: inline-block;
  margin: 0 8px;
}
.mark {
  display: inline-block;
  position: relative;
  top: 8px;
  margin-left: 4px;
}
.animated-check path {
  fill: none;
  stroke: #1ab856;
  stroke-width: 6;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: draw 1s linear forwards 1;
}

.animated-cross path {
  fill: none;
  stroke: var(--dark-red);
  stroke-width: 6;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
}

.animated-cross path:nth-of-type(1) {
  animation: draw 0.75s linear forwards 1;
}

.animated-cross path:nth-of-type(2) {
  animation: draw 0.75s linear forwards 1;
  animation-delay: 0.75s;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.lds-ring {
  display: inline-block;
  width: 25px;
  height: 25px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid var(--dark-red);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--dark-red) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
