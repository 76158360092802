import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'


Vue.use(Vuex)

export default new Vuex.Store({
  state: { 
    bgActive: "/img/bg.jpg",
    bgDef: "/img/bg.jpg",
    content: [{ slug: "kalendar", img: "1.jpg", name: "Kalendář" }]
  },
  getters: {
    getBg(state) {
      return state.bg
    }
  },
  mutations: {
    SET_BG(state, bg) {
      state.bg = bg;
    }
  },
  actions: {
    setBg({ commit }, bg) {
      commit('SET_BG', bg);
    }
  },
  modules: {
    auth
  }
})
