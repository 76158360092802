<template>
  <div id="footer">
    <div class="footer__social">
      <a href="https://www.facebook.com/SkautInfo/"
        ><img src="/img/facebook_darkred.png" alt="Facebook"
      /></a>
      <a href="https://twitter.com/skaut?lang=cs"
        ><img src="/img/twitter_darkred.png" alt="Twitter"
      /></a>
      <a href="https://www.instagram.com/skaut/?hl=cs"
        ><img src="/img/instagram_darkred.png" alt="Instagram"
      /></a>
      <a
        href="https://krizovatka.skaut.cz/organizace/kontakty/junak-cesky-skaut"
        ><img src="/img/web_darkred.png" alt="Web"
      /></a>
    </div>
    <div class="footer__copyright">
      Junák – český skaut, z. s. © <span><a href="https://krejsa.xyz">BK</a> {{ new Date().getFullYear() }}</span>
    </div>
    <div class="footer__login">
      <span v-if="auth"><button class="btn-link" @click="$emit('logout')">Odhlásit se</button><router-link to="/pridat-stranku" class="btn-link">Přidat stránku</router-link><router-link to="/nastaveni" class="btn-link">Nastavení</router-link></span>
      <button v-else class="btn-link" @click="$emit('show-login')">Přihlásit se</button>
    </div>
  </div>
</template>
<script>
export default {
    computed: {
        auth: function() {
            return this.$store.getters['auth/authenticated']
        }
    }
};
</script>
<style>
#footer {
  border-top: 1px solid #cbd5e0;
  padding: 32px 0;
  text-align: center;
  color: var(--dark-red);
}

.footer__social img {
  padding: 8px;
}
.footer__copyright {
  padding-bottom: 32px;
}
.footer__login,
.footer__login button {
  color: var(--mid-dark-gray);
}
</style>
