<template>
  <div>
    <transition name="slide">
      <div v-if="width > 768 || openMenu">
        <div id="nav" @mouseleave="changeBg($store.state.bgDef)" :style="menuLength">
          <div
            @click="toggleMenu()"
            class="nav__menu-icon--opened"
            v-if="width < 769"
          >
            Menu
          </div>
          <router-link
            v-for="(link, i) in this.$store.state.content"
            :key="i"
            :to="'/'+link.slug"
            @mouseover.native="changeBg(link.img)"
            @click.native="navigate(link.img)"
            >{{ link.name }}</router-link
          >
        </div>
      </div>
    </transition>
    <div v-if="width < 769 && !openMenu">
      <div @click="toggleMenu()" class="nav__menu-icon--closed">
        Menu
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "Navigation",
  props: [],
  data() {
    return {
      width: window.innerWidth,
      openMenu: false,
    };
  },
  methods: {
    changeBg: function (img) {
       this.$store.state.bgActive = img;
    },
    toggleMenu() {
      this.openMenu = !this.openMenu;
    },
    navigate(img) {
      this.openMenu = false;
      this.$store.state.bgDef = img;
    }
  },
  mounted() {
    window.onresize = () => {
      this.width = window.innerWidth;
    };
  },
  computed: {
    menuLength: function() {
      var len = this.$store.state.content.length;
      if(768 < this.width && this.width < 1024) {
        var repeat = (len % 2  == 0) ? len : len + 1;
        return `grid-template-columns: repeat(${repeat}, 1fr);`;
      } else if(this.width >= 1024) {
        return `grid-template-columns: repeat(${len}, 1fr);`;
      }
      return null
    }
  },
  created() {
    axios
      .get("/content.php")
      .then((res) => {
        this.$store.state.content = res.data;
        this.$store.state.content.forEach(element => {
          let image = new Image(); 
          image.src = element.img;
        });
        var currentLink = res.data.filter((link) => {
          return this.$route.path == `/${link.slug}`;
        });
        if (currentLink.length) {
          this.$store.state.bgDef = currentLink[0].img;
          this.changeBg(currentLink[0].img);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
};
</script>

<style>
#nav {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-items: center;
  color: #fff;
  font-size: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

#nav > a {
  display: block;
  width: 100%;
  padding: 24px 0;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
  text-decoration: none;
}

#nav > a:hover, #nav > a.router-link-active {
  color: var(--dark-red);/*#333;*/
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.3s;
  text-shadow: none;
}

@media only screen and (max-width: 1200px) {
  #nav > a {
    grid-column: span 1;
  }
}

@media only screen and (max-width: 1024px) {
  #nav > a {
    grid-column: span 2;
  }
}

@media only screen and (max-width: 768px) {
  #nav {
    background-color: rgba(0, 0, 0, 0.4);
  }
  #nav > a {
    grid-column: span 4;
  }
}

@media only screen and (max-width: 480px) {
  #nav > a {
    grid-column: span 8;
    padding: 10px 0;
  }
}

.nav__menu-icon--closed {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  font-size: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  padding: 24px 0;
  cursor: pointer;
}

.nav__menu-icon--opened {
  width: 100%;
  color: #fff;
  font-size: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  padding: 24px 0;
  grid-column: span 8;
  cursor: pointer;
}

.slide-enter-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.slide-leave-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.slide-enter-to,
.slide-leave {
  transform: scaleY(1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
