<template>
  <div>
    <div class="content">
      <h2>{{ content.name }}</h2>
      <div v-html="content.content"></div>
    </div>
    <div class="btn-group content__admin" v-if="auth">
      <button class="btn" @click="editContent()">
        Upravit stránku
      </button>
      <button class="btn" @click="deleteContent()">Smazat stránku</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "Obsah",
  components: {},
  data() {
    return {
      content: {
        id: null,
        slug: null,
        name: null,
        img: null,
        content: null,
      },
    };
  },
  methods: {
    deleteContent: function() {
      var confirmDelete = confirm(
        "Skutečně chcete natrvalo smazat tuto stránku?"
      );
      if (!confirmDelete) {
        return;
      }
      axios
        .delete("/content.php", { data: { slug: this.$route.params.slug } })
        .then(() => {
          this.$parent.navKey++;
          this.$store.state.bgActive = "/img/bg.jpg";
          this.$store.state.bgDef = "/img/bg.jpg";
          this.$router.replace({
            name: "Home",
          });
        })
        .catch((e) => {
          if (e.response.status == 403) {
            this.loading = false;
            this.$root.$children[0].showLogin = true;
          }
        });
    },
    editContent: function() {
      this.$router.push({
        path: "upravit",
        append: true,
      });
    },
  },
  computed: {
    ...mapGetters({
      auth: "auth/authenticated",
    }),
  },
  beforeRouteEnter(to, from, next) {
    axios
      .get("/content.php?slug=" + to.params.slug)
      .then((res) => {
        if (res.data.length) {
          next(($vm) => ($vm.content = res.data[0]));
        } else {
          next({ name: "Home" });
        }
      })
      .catch(() => {
        next({ name: "Home" });
      });
  },
  watch: {
    $route: function(to) {
      axios
        .get("/content.php?slug=" + to.params.slug)
        .then((res) => {
          if (res.data.length) {
            this.content = res.data[0];
          } else {
            this.$router.replace({ name: "Home" });
          }
        })
        .catch(() => {
          this.$router.replace({
            name: "Home",
          });
        });
    },
  },
};
</script>

<style>
.content {
  padding: 48px;
}

@media only screen and (min-width: 1200px) {
  .content {
    padding-left: calc(48px + 5vw);
    padding-right: calc(48px + 5vw);
  }
}

@media only screen and (max-width: 768px) {
  .content {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media only screen and (max-width: 480px) {
  .content {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.content h2 {
  font-family: "skautbold";
  font-size: 2.5rem;
  text-align: center;
}

.content__admin {
  margin: 8px auto;
  text-align: center;
}
</style>
