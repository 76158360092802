<template>
  <div id="app">
    <div
      id="header"
      :class="{ home: isHome }"
      ref="header"
      :style="`background-image: url(${$store.state.bgActive})`"
    >
      <div id="heading">
        <div>
          <router-link to="/" @click.native="changeBg"
            ><img src="/img/logo.png" alt=""
          /></router-link>
        </div>
        <div><h1 class="--text-shadow-2">Roverský rozcestník</h1></div>
      </div>
      <Navigation ref="nav" :key="navKey"></Navigation>
    </div>
    <router-view style="position: relative; top: 0" />
    <Footer
      v-if="!isHome"
      v-on:show-login="showLogin = true"
      v-on:logout="logout()"
    ></Footer>
    <modal
      v-if="showLogin"
      v-on:close-modal="
        showLogin = false;
        loginSuccess = null;
      "
      class="modal-half"
    >
      <div>
        <div>
          <input
            type="text"
            v-model="credentials.username"
            placeholder="Uživatelské jméno"
            class="--full-width"
          />
          <input
            type="password"
            v-model="credentials.password"
            placeholder="Heslo"
            class="--full-width"
          />
          <input
            type="text"
            v-model="credentials.special"
            placeholder="Special"
            class="--full-width --special"
          />
        </div>
        <button @click="login()" class="btn btn-red --full-width">
          Přihlásit se
        </button>
        <div v-if="loginSuccess">
          <Loader :type="loginSuccess" class="loader--inline"></Loader
          ><span v-if="loginSuccess == 'error'">
            Chybné uživatelské jméno nebo heslo!</span
          >
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import Modal from "@/components/Modal";
import { mapActions } from "vuex";
export default {
  components: {
    Navigation,
    modal: Modal,
    Footer,
  },
  data() {
    return {
      isHome: true,
      navKey: 8808,
      showLogin: false,
      credentials: {
        username: "",
        password: "",
        special: null,
      },
      loginSuccess: null,
    };
  },
  methods: {
    changeBg: function () {
      this.$store.state.bgActive = "/img/bg.jpg";
      this.$store.state.bgDef = "/img/bg.jpg";
    },
    ...mapActions({
      signIn: "auth/signIn",
      signOut: "auth/signOut",
    }),
    login: function () {
      if (this.credentials.special) {
        return;
      }
      this.loginSuccess = null;
      this.signIn(this.credentials)
        .then((success) => {
          this.loginSuccess = success ? "success" : "error";
          if (success) {
            this.showLogin = false;
            this.loginSuccess = null;
          }
        })
        .catch((e) => {
          this.loginSuccess = false;
          console.log("Chyba: " + e);
        });
    },
    logout: function () {
      this.signOut().then(() => {
        this.$router.replace({
          name: "Home",
        });
        this.changeBg();
      });
    },
  },
  watch: {
    $route: function () {
      if (this.$route.path === "/") {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    },
  },
  created() {
    if (this.$route.path === "/") {
      this.isHome = true;
    } else {
      this.isHome = false;
    }
  },
  mounted() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight*0.01}px`);
    window.onresize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight*0.01}px`);
    };
  },
};
</script>

<style>
:root {
  --xs: 480px;
  --sm: 768px;
  --md: 1024px;
  --lg: 1200px;
  --white: #ffffff;
  --mid-red: #c74040;
  --mid-dark-red: #a71d1d;
  --dark-red: #980000;
  --light-gray: #f3f5f8;
  --mid-gray: #cbd5e0;
  --mid-gray-transparent: #cbd5e080;
  --mid-dark-gray: #98a3ae;
  --dark-gray: #6c7075;
  --light-green: #93d17b;
  --mid-green: #56b530;
  --dark-green: #2d6b14;
  --light-orange: #e2c287;
  --mid-orange: #e0a639;
  --dark-orange:  #ac7b21;
}

#header {
  width: 100%;
  height: 80vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.5s ease-in-out;
}

#header.home {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

#heading {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  #heading > div {
    grid-column: span 5;
  }
}

@media only screen and (max-width: 480px) {
  #heading > div {
    grid-column: span 5;
  }
}

.--text-center {
  text-align: center;
}

.--text-shadow-1 {
  text-shadow: 2px 2px 0px #333;
}

.--text-shadow-2 {
  text-shadow: 2px 2px 10px #333;
}

html {
  font-size: 16px;
  font-family: "themix", sans-serif;
}
body {
  margin: 0;
  background-color: var(--light-gray);
}

a {
  color: inherit;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

h1 {
  font-family: "skautbold", sans-serif;
  font-size: 3rem;
  color: #fff;
}

h1,
h2,
h3,
h4,
h5 {
  word-wrap: break-word;
}

input {
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  padding: 8px 16px;
  margin: 4px 0;
  box-sizing: border-box;
}

.--full-width {
  width: 100%;
}

.--half-width {
  width: calc(50% - 4px);
}

.--half-width:nth-of-type(even) {
  margin-left: 8px;
}

@media only screen and (max-width: 768px) {
  .--half-width {
    width: calc(100% - 8px);
  }
  .--half-width:nth-of-type(even) {
    margin-left: 0;
  }
}

.--one-third-width {
width: calc(33.33% - 6px);
margin-left: 8px;
}

.--one-third-width:nth-of-type(3n+1) {
margin-left: 0px;
}

@media only screen and (max-width: 768px) {
  .--one-third-width {
    width: calc(100% - 8px);
    margin-left: 0;
  }
}

.--one-fourth-width {
width: calc(25% - 6px);
margin-left: 8px;
}

.--one-fourth-width:nth-of-type(4n+1) {
margin-left: 0px;
}

@media only screen and (max-width: 768px) {
  .--one-fourth-width {
    width: calc(100% - 8px);
    margin-left: 0;
  }
}

input:focus,
input:active {
  border: 1px solid var(--dark-gray);
  outline: none;
}

.btn {
  display: inline-block;
  border: 1px solid var(--mid-dark-gray);
  border-radius: 8px;
  padding: 8px 16px;
  margin: 4px 0;
  cursor: pointer;
  background-color: var(--mid-gray);
  transition: all 0.3s;
  color: var(--dark-gray);
  text-decoration: none;
  font-size: 1rem;
}

.btn-group > .btn {
  margin-left: 4px;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn:hover {
  border: 1px solid var(--dark-gray);
  background-color: var(--mid-dark-gray);
}

.btn-red {
  background-color: var(--mid-red);
  border-color: var(--mid-dark-red);
  color: #fff;
}

.btn-link {
  background: none !important;
  border: none;
  font-family: "themix";
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
  padding: 8px 16px;
}

.btn-red:hover {
  background-color: var(--mid-dark-red);
  border-color: var(--dark-red);
}

.btn:focus,
.btn:active,
.btn-link {
  outline: none;
}

/* AI generated */

.multiselect-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  padding: 8px 16px;
  margin: 4px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.dropdown-toggle:focus,
.dropdown-toggle:active {
  border: 1px solid var(--dark-gray);
  outline: none;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: white;
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
}

.dropdown-options label {
  display: block;
  margin: 4px 0;
}

.dropdown-options input[type="checkbox"] {
  margin-right: 8px;
}



/* DEL */

select {
border: 1px solid var(--mid-gray);
border-radius: 8px;
padding: 8px 16px;
margin: 4px 0;
box-sizing: border-box;
background-color: #fff; /* optional, if you want to give it a white background */
}

select:focus,
select:active {
border: 1px solid var(--dark-gray);
outline: none;
}

input[type=checkbox],
input[type=radio] {
display: inline-block; /* to make them inline with other elements /
margin-right: 8px; / to add some space between them and other elements */
}

input[type=checkbox] + label,
input[type=radio] + label {
display: inline-block;
border: 1px solid var(--mid-dark-gray);
border-radius: 8px;
padding: 8px 16px;
margin: 4px 0;
cursor: pointer;
background-color: var(--mid-gray);
transition: all 0.3s;
color: var(--dark-gray);
text-decoration: none;
font-size: 1rem;
}

input[type=checkbox]:checked + label,
input[type=radio]:checked + label {
background-color: var(--mid-dark-gray);
color: #fff;
}

input[type=checkbox] + label:hover,
input[type=radio] + label:hover {
background-color: var(--mid-dark-gray);
border-color: var(--dark-gray);
}

input[type=checkbox]:focus + label,
input[type=radio]:focus + label {
outline: none;
}

/* # AI generated */

.--flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@font-face {
  font-family: "themix";
  src: url("/fonts/TheMix_LT_400.eot");
  src: url("/fonts/TheMix_LT_400.eot? #iefix")
    format("embedded-opentype");
  src: url("/fonts/TheMix_LT_400.woff") format("woff"),
    url("/fonts/TheMix_LT_400.woff2") format("woff2"),
    url("/fonts/TheMix_LT_400.otf") format("opentype"),
    url("/fonts/TheMix_LT_400.svg#themix") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "themix";
  src: url("/fonts/TheMix_LT_400i.eot");
  src: url("/fonts/TheMix_LT_400i.eot? #iefix")
    format("embedded-opentype");
  src: url("/fonts/TheMix_LT_400i.woff") format("woff"),
    url("/fonts/TheMix_LT_400i.woff2") format("woff2"),
    url("/fonts/TheMix_LT_400i.otf") format("opentype"),
    url("/fonts/TheMix_LT_400i.svg#themix") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "themix";
  src: url("/fonts/TheMix_LT_700.eot");
  src: url("/fonts/TheMix_LT_700.eot? #iefix")
    format("embedded-opentype");
  src: url("/fonts/TheMix_LT_700.woff") format("woff"),
    url("/fonts/TheMix_LT_700.woff2") format("woff2"),
    url("/fonts/TheMix_LT_700.otf") format("opentype"),
    url("/fonts/TheMix_LT_700.svg#themix") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "themix";
  src: url("/fonts/TheMix_LT_700i.eot");
  src: url("/fonts/TheMix_LT_700i.eot? #iefix")
    format("embedded-opentype");
  src: url("/fonts/TheMix_LT_700i.woff") format("woff"),
    url("/fonts/TheMix_LT_700i.woff2") format("woff2"),
    url("/fonts/TheMix_LT_700i.otf") format("opentype"),
    url("/fonts/TheMix_LT_700i.svg#themix") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "skautbold";
  src: url("/fonts/skaut-bold-webfont.eot");
  src: url("/fonts/skaut-bold-webfont.eot? #iefix")
    format("embedded-opentype");
  src: url("/fonts/skaut-bold-webfont.woff")
      format("woff"),
    url("/fonts/skaut-bold-webfont.otf")
      format("opentype"),
    url("/fonts/skaut-bold-webfont.svg#skautbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::selection {
  background: #980000;
  color: #fff;
}
input.--special {
  display: none;
}



blockquote {
  border-top: 4px solid var(--dark-red);
  border-bottom: 4px solid var(--dark-red);
  border-radius: 16px;
  margin: 0;
  padding: 24px;
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;
}
blockquote > p:last-of-type:not(:first-of-type) {
  font-size: 1rem;
  font-style: normal;
}
blockquote > p:last-of-type:not(:first-of-type)::before {
  content: "\2013\2002";
}

ul {
  list-style-image: url("/img/bullet.png");
}

.iframe__overlay {
  display: none;
}



img.img__responsive {
  display: block;
  max-width: 100%;
  height: auto;
  padding: 16px 0;
}

img.img__left {
  float: left;
  max-width: 50%;
  padding: 16px 16px 16px 0;
}

img.img__right {
  float: right;
  max-width: 50%;
  padding: 16px 0 16px 16px;
}

img.img__center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0;
  max-width: 100%;
  max-height: 100%;
}

img.img__cover {
  display: block;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  padding: 16px 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  
}

td {
  display: table-cell;
}

table {
  table-layout: fixed;
}

table td {
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid var(--mid-gray);
}

table tr:first-child td {
  font-weight: bold;
  border-bottom: 1px solid var(--mid-dark-gray);;
}


table tr:last-child td {
  border-bottom: none;
}

</style>
