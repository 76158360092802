<template>
  <div>
    <div class="content">
      <h2>Časopis Roverský kmen</h2>
      <div>
        <p>
          <strong><em>Sloužíme těm, kdo slouží!</em> </strong>
        </p>
        <p>
          <strong
            ><em
              >Roveři a&nbsp;rangers jsou nejrozmanitější výchovnou kategorií
              skautského společenství. To se zrcadlí i&nbsp;v&nbsp;našem
              časopise, přinášejícím skautská i&nbsp;lifestylová témata. Chceme
              být hlasem dospívající skautské generace stejně jako místem
              vzájemné inspirace a&nbsp;podpory.</em
            >
          </strong>
        </p>
        <h3>Co se v Roverském kmeni dozvíš?</h3>
        <ul>
          <li>Spoustu skvělých informací</li>
          <li>Jak zažít ten pravý rovering</li>
          <li>Inspiraci a náladu žít skautingem naplno</li>
        </ul>
        <h3>Články z Roverského kmene</h3>
        <p>
          Všechny články najdeš na webu
          <a
            href="https://kmen.skauting.cz/"
            target="_blank"
            rel="noopener noreferrer nofollow"
            >Roverského kmene</a
          >
          nebo v následujícím přehledu. Hledáš články na určité téma? Např.
          první pomoc, skautská historie,vaření, výprava? Podívej se na stránku
          <a
            href="https://casopisy.skaut.cz/"
            target="_blank"
            rel="noopener noreferrer nofollow"
            >casopisy.skaut.cz</a
          >, kde můžeš pomocí tagů vyhledávat ve všech časopisech a časopisy si
          pak v PDFkách stáhnout.
        </p>
      </div>
      <div>
        <div v-for="post in posts" :key="post.id" class="post">
          <img :src="post.jetpack_featured_media_url" alt class="post__img" />
          <div class="post__text">
            <h3 v-html="post.title.rendered"></h3>
            <a
              v-for="(category, i) in post._embedded['wp:term'][0]"
              :key="'cat' + i"
              class="post__category"
              :href="category.link"
              target="_blank"
              >{{ category.name }}</a
            >
            <p v-html="post.excerpt.rendered"></p>
            <a
              :href="post.link"
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-red"
              >Přečíst celý článek</a
            >
          </div>
        </div>
        <div class="--text-center">
          <Loader
            :show="loader.show"
            :type="loader.type"
            class="loader--inline"
          ></Loader>
          <span v-if="loader.type == 'error'">
            Není dostupný žádný další obsah</span
          ><span v-if="loader.type == 'loading' && !loader.show">
            <em>Pro načtení více článků scrolluj dolů nebo klikni <span class="link" @click="getPosts()">sem</span>...</em></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Kmen",
  data() {
    return {
      posts: [],
      nextPage: 1,
      loader: {
        show: true,
        type: "loading",
      },
    };
  },
  methods: {
    getPosts: function () {
      this.loader.show = true;
      axios
        .get(
          `https://kmen.skauting.cz/wp-json/wp/v2/posts?per_page=5&page=${this.nextPage}&_fields=id,excerpt,title,link,jetpack_featured_media_url,categories,_links,_embedded&_embed=wp:term`
        )
        .then((res) => {
          this.posts = this.posts.concat(res.data);
          if (res.headers["x-wp-totalpages"] == this.nextPage) {
            this.loader.type = "error";
          } else {
            this.nextPage++;
            this.loader.show = false;
          }
        })
        .catch((e) => {
          console.log("Chyba: ", e);
        });
    },
    scroll: function () {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 100 >
          document.documentElement.offsetHeight;

        if (
          bottomOfWindow &&
          !this.loader.show &&
          this.loader.type == "loading"
        ) {
          this.getPosts();
        }
      };
    },
  },
  created() {
    this.getPosts();
  },
  mounted() {
    this.scroll();
  },
};
</script>

<style>
.post {
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 16px;
  background-color: #fff;
}

.post__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .post__img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    grid-column: span 3;
  }

  .post__text {
    grid-column: span 3;
  }
}

.post__category {
  font-size: 0.8rem;
  background-color: var(--mid-gray);
  border: none;
  color: black;
  padding: 4px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;
  margin-left: 4px;
}
.post__category:first-of-type {
  margin-left: 0;
}
</style>
