import axios from "axios";
import router from "../router/index.js"

export default {
  namespaced: true,

  state: {
    token: null,
  },
  getters: {
    authenticated(state) {
      var authenticated = state.token ? true : false;
      return authenticated;
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_INT(state, int) {
      state.int = int;
    },
  },
  actions: {
    async signIn({ dispatch }, credentials) {
      var formData = new FormData();
      formData.append("username", credentials.username);
      formData.append("password", credentials.password);
      let response = await axios.post("/auth.php", formData);
      dispatch("attempt", response.data.token);
      if(response.data.token) {
        return true
      } else {
        return false
      }
    },

    async init({ commit, dispatch }, token) {
      if (!token) {
        return;
      }
      commit("SET_TOKEN", token);
      axios
          .post("/session.php", { data: { token: token } })
          .then(() => {
            dispatch("ping");
          })
          .catch(() => {
            commit("SET_TOKEN", null);
          });
    },

    async attempt({ commit, state, dispatch }, token) {
      if (token) {
        commit("SET_TOKEN", token);
        dispatch("ping");
      }

      if (!state.token) {
        return;
      }
    },

    async ping({ commit, state }) {
      var int = setInterval(function() {
        axios
          .post("/session.php", { data: { token: state.token } })
          .catch(() => {
            commit("SET_TOKEN", null);
            clearInterval(state.int);
            router.replace({
              name: "Home",
            });
          });
      }, 60000);
      commit("SET_INT", int);
    },

    signOut({ commit, state }) {
      return axios
        .delete("/auth.php", { data: { token: state.token } })
        .then(() => {
          commit("SET_TOKEN", null);
          clearInterval(state.int);
        });
    },
  },
};
