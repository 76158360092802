<template>
  <div class="modal__wrapper" @click.self="closeModal">
    <div class="modal">
      <span class="modal__close" @click="closeModal">&times;</span>
      <div class="modal__content" v-on:close-modal="closeModal">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    closeModal: function() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style>
.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  padding: 48px;
  box-sizing: border-box;
}
.modal {
  position: relative;
  border-radius: 24px;
  background-color: var(--light-gray);
  z-index: 2;
  padding: 48px;
  box-sizing: border-box;
}

.modal-full .modal {
  height: 100%;
  width: 100%;
}

.modal-fixed-sm .modal {
  height: 100%;
  width: var(--sm);
  margin: 0 auto;
}

.modal-half .modal {
  height: 100%;
  width: 50%;
  width: 600px;
  margin: 0 auto;
}

.modal-scrollable .modal__content {
  overflow-y: auto;
}

/* Change the track of modal scrollable to white */
.modal-scrollable .modal__content::-webkit-scrollbar-track {
  background-color: var(--mid-gray);
}

@media only screen and (max-width: 768px) {
  .modal-half .modal, .modal-fixed-sm .modal  {
    width: 100%;
  }

  .modal-fixed-sm .modal {
    width: 100%;
    margin: 0 auto;
    padding: 24px;
  }

  .modal__wrapper {
    padding: 24px;
  }
}

.modal__content {
  width: 100%;
  height: 100%;
}

.modal__content > div {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 24px;
  color: var(--mid-dark-gray);
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.15s;
}

.modal__close:hover {
  color: var(--dark-gray);
  transition: all 0.15s;
}

.modal__action {
  border-top: 1px solid var(--mid-dark-gray);
  padding-top: 8px;
}

.modal__content h3 {
  margin-top: 0;
}
</style>
