import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import Loader from "@/components/Loader";


require('@/store/subscriber')

Vue.use(Vuex);
Vue.component('Loader', Loader);

Vue.config.productionTip = false;

store.dispatch('auth/init', localStorage.getItem('token')).then(() => {

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

})
